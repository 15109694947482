.age-verification-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    max-width: unset;
    background-color: white;
    z-index: 998;
    display: none;
    background-color: rgba(0,0,0,0.5);

    .a, a {
        display: inline-block;
    }

    &.show {
        display: block;
    }

    .logo {
        position: absolute;
        top: 48px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 140px;
    }

    .verification {
        width: 50%;
        height: 0px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        text-align: center;
        @media screen and (max-width: 680px){
            width: 80%;
        }
        @media screen and (max-width: 576px){
            width: 90%;
            height: 300px;
        }

        .bg-image {
            position: absolute;
            top: -280px;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 1;
            @media screen and (max-width: 576px){
                width: 200px;
                margin: 0 auto;
                top: 180px !important;
            }
            @media screen and (max-height: 740px){
                width: 200px;
                margin: 0 auto;
                top: -220px;
            }
        }

        .wrap {
            z-index: 2;
            position: relative;
            background: white;
            padding: 35px 0;
            margin: -30% 0;

            &:before {
                content: "";
                background-image: url(../images/age_paper_left.png);
                left: 0;
                bottom: 0;
                height: 100%;
                width: 70px;
                position: absolute;
                background-size: contain;
                background-position-y: bottom;
                background-position-x: left;
                background-repeat: no-repeat;
                @media screen and (max-width: 1200px){
                    width: 35px;
                }
            }
            &:after {
                content: "";
                background-image: url(../images/age_paper_right.png);
                right: 0;
                top: 0;
                height: 100%;
                width: 70px;
                position: absolute;
                background-size: contain;
                background-position-y: top;
                background-position-x: right;
                background-repeat: no-repeat;
                @media screen and (max-width: 1200px) {
                    width: 35px;
                }
            }


            @media screen and (max-width: 768px){
                margin: 0;
            }


            .title {
                font-family: 'Droid Serif';
                font-size: 20px;
                color: #555;
                line-height: 32px;
                padding-bottom: 30px;
                margin: 0 70px;

                @media screen and (max-width: 1200px){
                    margin: 0 35px;
                }
                @media screen and (max-width: 640px){
                    font-size: 16px;
                    line-height: 28px;
                }
            }
            .buttons {
                text-align: center;

                .a, a {
                    &:hover {
                        text-decoration: none;
                    }
                    &.age-invalid {
                        margin-left: 18px;
                        @media screen and (max-width: 640px){
                            margin-left: 8px;
                        }
                    }
                    &.age-valid {
                        margin-right: 18px;
                        @media screen and (max-width: 640px){
                            margin-right: 8px;
                        }
                    }
                }

                .verification-button {
                    width: 240px;
                    @media screen and (max-width: 1200px){
                        width: 140px;
                    }
                    height: 40px;
                    font-size: 22px;
                    font-weight: 500;
                    @media screen and (max-width: 640px){
                        width: 100px;
                        height: 30px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    #navbar-search {
        display: none !important;
    }

    .action.nav-toggle {
        display: none !important;
    }

    .page-main {
        background-color: #eee;
    }

    .verification-wrapper {
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;
        @media screen and (max-width: 576px){
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .inner-wrapper {
            background-color: white;
            box-shadow: 0 1px 2px rgba(0,0,0,0.2);
            padding: 60px 20px;
            margin: 10px;

            .title {
                font-family: 'Droid Serif';
                color: #777;
                font-size: 16px;
                line-height: 28px;
                margin-bottom: 30px;
            }
            .buttons {
                margin-bottom: 40px;
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;

                .a, a {
                    padding: 20px;
                    color: white;
                    font-family: 'Montserrat';
                    border-radius: 2px;
                    width: 220px;
                    margin: 10px;

                    &.age-invalid {
                        background-color: #ee5a5a;
                        &:hover {
                            background-color: #f16868;
                        }
                    }
                    &.age-valid {
                        background-color: $primary-yellow;
                        &:hover {
                            background-color: lighten($primary-yellow, 2);
                        }
                    }
                    &:hover {
                        text-decoration: none;
                    }

                    strong {
                        display: block;
                        color: white;
                        text-transform: uppercase;
                        font-size: 18px;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }
                }
            }
            .info {
                font-family: 'Droid Serif';
                color: #777;
                font-size: 14px;
                line-height: 24px;

                .a, a {
                    color: $primary-yellow;
                }
            }
        }
    }

}
